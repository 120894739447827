<template>
    <footer class="hide-on-med-and-up">
        <router-link id="library" to="/library" class="mobile-link">
            <div class="icon">
                <i class="small material-icons">book</i>
            </div>
            <div class="text">{{ t('navigation.library') }}</div>
        </router-link>
        <router-link id="add" to="/add" class="mobile-link">
            <div class="icon">
                <i class="small material-icons">add</i>
            </div>
            <div class="text">{{ t('navigation.addBook') }}</div>
        </router-link>
        <router-link id="home" to="/home" class="mobile-link">
            <div class="icon">
                <i class="small material-icons">home</i>
            </div>
            <div class="text">{{ t('navigation.start') }}</div>
        </router-link>
    </footer>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import router from '../router';
import { useI18n } from 'vue-i18n';

export default {
    setup() {
        const { t } = useI18n();
        onMounted(() => {
            let links = document.querySelectorAll('.mobile-link');
            links.forEach(link => {
                let isFingerOnLink = false;
                let touchend = () => {
                    if(isFingerOnLink) router.push(link.id);
                    else link.classList.remove('touched');
                }
                link.onclick = (e) => {
                    link.classList.add('touched');
                    setTimeout(() => link.classList.remove('touched'), 200);
                }
                link.oncontextmenu = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    isFingerOnLink = false;
                    touchend();
                } 
                link.ontouchstart = () => {
                    isFingerOnLink = true;
                    link.classList.add('touched');
                }
                link.ontouchmove = (e) => {
                    let currElem = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
                    let close = currElem.closest(`#${link.id}`);
                    if(!close) {
                        isFingerOnLink = false;
                        touchend();
                    }
                }
            })
        })
        return {
            t
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    position: fixed;
    z-index: 2;
    background-color: #FFF;
    bottom: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    border-top: solid 1px #8bc34a;
    padding: 12px 0 3px 0;
    text-align: center;
    .mobile-link {
        border-radius: 36px;
        user-select: none;
        transition: all .1s ease-out;
        i {
            color: #999;
        }
        .text {
            display: none;
        }
        &.touched {
            background-color: #e4f3d2;
            box-shadow: 0 -4px 0 8px #e4f3d2;
            transition: all .2s ease-out;
        }
        &.router-link-active {
            .icon {
                margin-top: -9px;
                i {
                    color: #8bc34a;
                }
            }
            .text {
                color: #8bc34a;
                padding: 0;
                margin: -6px 0 0 0;
                font-size: 10px;
                display: block;
            }
        }
    }
}

@media(hover: hover) and (pointer: fine) {
    .mobile-link:hover {
        background-color: #e4f3d2;
        box-shadow: 0 -4px 0 8px #e4f3d2;
        transition: all .4s;
    }
}
</style>