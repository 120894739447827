import Requester from './Requester';

class UserRequester extends Requester {
    static async login() {
        
    }
    static async register(newUser) {
        try {
            await Requester.postJson('/api/user/register', newUser);
        } catch (err) {
            // TODO: map errors
            console.log(err);
            throw err;
        }
    }
    static async changeLanguage(lang) {
        try {
            await Requester.putJsonSecure('/api/user/language', { lang: lang.id });
            return true;
        } catch(err) {
            console.log(err);
            return false;
        }
    }
    static async changeUsername(username) {
        try {
            await Requester.putJsonSecure('/api/user/username', { username: username });
            return true;
        } catch(err) {
            console.log(err);
            return false;
        }
    }
}

export default UserRequester;