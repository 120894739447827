import { createStore } from 'vuex'
import { UserModule } from './user'
import { BookModule } from './book'

export default createStore({
    state: {
		languages: [],
		currentLanguage: {}
	},
    mutations: {
		SET_LANGUAGES(state, langs) {
            state.languages = langs;
        },
		SET_CURRENT_LANGUAGE(state, lang) {
			state.currentLanguage = lang;
		}
	},
    actions: {
		setLanguages({ commit }, langs) {
            commit('SET_LANGUAGES', langs);
        },
		setCurrentLanguage({ commit }, lang) {
            commit('SET_CURRENT_LANGUAGE', lang);
        }
	},
    modules: {
        User: UserModule,
        Book: BookModule
    }
})
