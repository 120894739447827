<template>
    <div class="center-align">
        <div class="row">
            <div class="col s12">
                <h4>{{ t('index.title') }}</h4>
            </div>
        </div>

        <div class="introduction">
            <div class="description">
                <div v-html="t('index.description')"></div>
                <div class="row">
                    <router-link to="/login" class="btn-flat light-green">{{ t('index.login') }}</router-link>
                    <router-link to="/register" class="btn-flat light-green">{{ t('index.getStarted') }}</router-link>
                </div>
            </div>
            <div class="image">
                <img id="bookshelf-img" alt="Bookshelf Graphic" src="@/assets/bookshelf.png">
            </div>
        </div>

        <div id="gallery" class="row light-green">
            <div class="col s12">
                <h4>{{ t('index.gallery') }}</h4>
                <div class="gallery">
                    <div class="image">
                        <img class="materialboxed" src="@/assets/scanner.png" async>
                    </div>
                    <div class="image">
                        <img class="materialboxed" src="@/assets/results.png" async>
                    </div>
                    <div class="image">
                        <img class="materialboxed" src="@/assets/home.png" async>
                    </div>
                    <div class="image">
                        <img class="materialboxed" src="@/assets/library.png" async>
                    </div>
                </div>
            </div>
        </div>

        <div id="sources" class="row">
            <div class="col s12 center-align">
                <h4>{{ t('index.dataSources') }}</h4>
                <ul>
                    <li><a href="https://isbndb.com">ISBNDB</a></li>
                    <li><a href="https://www.worldcat.org">Worldcat</a></li>
                    <li><a href="https://www.bookfinder.com">Bookfinder</a></li>
                </ul>
            </div>
        </div>

        <div id="techstack" class="row light-green">
            <div class="col s12 center-align">
                <h4>{{ t('index.techStack') }}</h4>
                <ul>
                    <li><a href="https://nodejs.org">Node.js</a></li>
                    <li><a href="http://expressjs.com">Express</a></li>
                    <li><a href="https://sqlite.org/index.html">SQLite</a></li>
                    <li><a href="https://vuejs.org/">Vue.js</a></li>
                    <li><a href="https://materializecss.com">MaterializeCSS</a></li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col s12">
                © 2021 <a href="https://mikolaj.cankudis.net">Mikolaj Cankudis</a>
            </div>
        </div>

    </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

export default {
    name: 'Index',
    setup() {
        const { t } = useI18n();
        onMounted(() => {
            let elems = document.querySelectorAll('.materialboxed');
            M.Materialbox.init(elems, {});
        })
        return {
            t
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 100%;
}
#bookshelf-img {
    width: clamp(128px, 100%, 256px);
}
.introduction {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    .description {
        width: 50%;
        .btn-flat {
            margin: 8px;
        }
    }
    .image {
        width: clamp(128px, 50%, 256px);
    }
}
.gallery {
    width: clamp(150px, 100%, 1400px);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .image {
        width: clamp(140px, 24%, 320px);
        margin-bottom: 8px;
    }
}
@media screen and ( max-width: 600px) {
    .introduction {
        .description {
            width: 90%;
        }
    }
}
.description {
    font-size: 16px;
}
</style>