<template>
    <div class="slider-from-right slide-from-right">
        <div class="hide-on-med-and-up logo">
            <router-link to="/index">
                <img
                    alt="Hometheca Logo"
                    src="@/assets/logo_nav.png"
                    class="responsive-img">
            </router-link>
        </div>
        <div class="container">
            <div class="user-info">
                <h4> {{ t('home.welcome') }} {{ user?.username }}</h4>
                <div>{{ t('home.youHave') }} {{ state.nOfBooks }} {{ t('home.inLibrary') }}</div>
            </div>
            <div class="controls">
                <div class="red btn-flat" @click="logOut()">
                    <i class="material-icons left">logout</i>
                    {{ t('home.logout') }}
                </div>
                <div class="light-green btn-flat" @click="exportLibrary()">
                    <i class="material-icons left">file_download</i>
                    {{ t('home.libraryExport') }}
                </div>
                <div class="light-green btn-flat dropdown-trigger" data-target="languages">
                    <i class="material-icons left">language</i>
                    {{ t('home.changeLanguage') }}
                </div>
                <ul id="languages" class="dropdown-content">
                    <li
                        v-for="lang in languages"
                        :key="lang.id"
                        :class="{ active: lang.abbreviation === user?.locale }"
                        @click="changeLanguage(lang)">
                        <a>{{ lang.localName }}</a>
                    </li>
                </ul>
                <div data-target="settings-modal" class="btn-flat light-green modal-trigger">
                    <i class="material-icons left">edit</i>
                    {{ t('home.editProfile') }}
                </div>
            </div>
            <h5>{{ t('home.lastAddedBooks') }}</h5>
            <ul class="collection">
                <div v-if="state.loading" class="preloader-wrapper small active">
                    <div class="spinner-layer spinner-green-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div><div class="gap-patch">
                            <div class="circle"></div>
                        </div><div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                
                <li class="collection-item avatar head-row hide-on-small-only">
                    <div class="row">
                        <div class="col m3 book-attribute">{{ t('book.title') }}</div>
                        <div class="col m3 book-attribute">{{ t('book.authors') }}</div>
                        <div class="col m3 book-attribute">{{ t('book.publisher') }}</div>
                        <div class="col m2 book-attribute">{{ t('book.publishDate') }}</div>
                        <div class="col m1 book-attribute">{{ t('book.copies') }}</div>
                    </div>
                </li>

                <BookInLibrary
                    v-for="book in state.recentBooks"
                    :key="book.title"
                    v-bind="{ book: book, shortView: true }"
                    @book-updated="update()"
                    @book-deleted="update()" />
            </ul>
        </div>

        <div id="settings-modal" class="modal">
            <div class="modal-content">

                <h5>{{ t('home.editProfile') }}</h5>
                <h6 class="col s12">{{ t('home.changeUsername') }}</h6>
                <form class="col s12" @submit.prevent="changeUsername()">
                    <div class="row">
                        <div class="input-field col s12">
                            <input
                                id="new-name"
                                v-model="state.newName"
                                type="text"
                                class="validate">
                            <label for="new-name">{{ t('home.newName') }}</label>
                        </div>
                    </div>
                    <div class="row center-align">
                        <div class="input-field col s12">
                            <button
                                id="submit-new-name"
                                type="submit"
                                class="btn-flat light-green lighten-1">
                                {{ t('util.save') }}
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BookInLibrary from "../components/BookInLibrary";
import BookRequester from '../classes/BookRequester';
import UserRequester from '../classes/UserRequester';
import { setI18nLanguage, loadLocaleMessages } from '../lang'

export default {
    name: 'Home',
    components: {
        BookInLibrary
    },
    setup() {
        const i18n = useI18n();
        const { t } = i18n;
        let store = useStore();
        let router = useRouter();
        let user = computed(() => store?.state?.User?.user);
        let languages = computed(() => store?.state?.languages);
        let state = reactive({
            nOfBooks: 0,
            recentBooks: [],
            loading: false,
            newName: ''
        })
        let update = async() => {
            state.loading = true;
            let n = await BookRequester.getLibraryLength();
            state.nOfBooks = `${n} ${getBooksWord(n)}`;
            state.recentBooks = await BookRequester.fetchRecentBooks();
            state.loading = false;
        }
        onMounted(() => {
            update();
            let elems = document.querySelectorAll('.dropdown-trigger');
            M.Dropdown.init(elems, {});
            let modals = document.querySelectorAll('.modal');
            M.Modal.init(modals, { preventScrolling: true, onOpenEnd: () => M.updateTextFields() });
        })
        let logOut = () => {
            store.dispatch('User/logOut');
            M.toast({ html: t('home.loggingOut'), displayLength: 1000 });
            router.push('login');
        }
        let getBooksWord = (n) => {
            if(n === 1) return t('book.one');
            if(n > 4 && n < 22) return t('book.manySpecial');
            n = n.toFixed();
            switch (n[n.length-1]) {
                case "1":
                    return t('book.manySpecial');
                case "2":
                    return t('book.many');
                case "3":
                    return t('book.many');
                case "4":
                    return t('book.many');
            }
            return t('book.manySpecial');
        }
        let exportLibrary = () => {
            let books = store.state.Book.books;
            let date = new Date();
            let filename = `library_${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}.json`;
            books = books.map(book => book.getExport())
            books = JSON.stringify(books);
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(books));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            let x = document.createDocumentFragment();
            x.appendChild(element);
            element.click();
        }
        let changeLanguage = async(lang) => {
            let success = await UserRequester.changeLanguage(lang);
            await loadLocaleMessages(i18n, lang.abbreviation);
            setI18nLanguage(i18n, lang.abbreviation);
            if(!success) 
                return M.toast({
                    html: t('home.errors.changeLanguageFailed'),
                    displayLength: 1000,
                    classes: 'red'
                });
            let user = store?.state?.User?.user;
            user.locale = lang.abbreviation;
            localStorage.setItem('user', JSON.stringify(user));
            M.toast({
                html: t('home.messages.languageChanged'),
                displayLength: 1000,
                classes: 'green'
            });
        }
        let changeUsername = async() => {
            if(!state.newName || state.newName.length < 4)
                return M.toast({
                    html: t('home.errors.usernameTooShort'),
                    displayLength: 2000,
                    classes: 'red'
                });
            let success = await UserRequester.changeUsername(state.newName);
            if(!success) 
                return M.toast({
                    html: t('home.errors.changeUsernameFailed'),
                    displayLength: 2000,
                    classes: 'red'
                });
            let user = store?.state?.User?.user;
            user.username = state.newName;
            localStorage.setItem('user', JSON.stringify(user));
            M.toast({
                html: t('home.messages.usernameChanged'),
                displayLength: 2000,
                classes: 'green'
            });
            state.newName = '';
        }
        return {
            user,
            state,
            languages,
            logOut,
            getBooksWord,
            exportLibrary,
            changeLanguage,
            changeUsername,
            update,
            t
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    margin: 6px 0 0 6px;
}
.controls .btn-flat {
    margin-right: 4px;
    margin-bottom: 4px;
}
.collection {
    .collection-item.avatar.head-row {
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 0px;
        user-select: none;
        .row {
            margin: 0;
            .col.book-attribute {
                vertical-align: middle;
                line-height: 10px;
                cursor: pointer;
                border-radius: 4px;
                padding: 10px 4px;
                i {
                    line-height: 0;
                    vertical-align: middle;
                }
            }
        }
    }
}
.modal .row {
    margin-bottom: 0;
}
</style>