<template>
    <div id="main" class="container">
        <div class="row">
            <div class="col s12">
                <img
                    alt="Hometheca Logo"
                    src="@/assets/logo_512x512.png">
            </div>
        </div>
        <div class="row">
            <h5>{{ t('signUp.heading') }}</h5>
            <form class="col s12" @submit.prevent="registerUser()">
                <div class="row">
                    <div class="input-field col s12">
                        <input
                            id="username"
                            v-model="state.newUser.username"
                            type="text"
                            class="validate">
                        <label for="username">{{ t('signUp.username') }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <input
                            id="email"
                            v-model="state.newUser.email"
                            type="email"
                            class="validate">
                        <label for="email">{{ t('signUp.email') }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s10 m11">
                        <input id="password" v-model="state.newUser.password" :type="state.passInputType">
                        <label for="password">{{ t('signUp.password') }}</label>
                    </div>
                    <i class="material-icons postfix no-select" @click="togglePasswordVisibility()">
                        {{ state.visibilityIcon }}</i>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <button id="submit" type="submit" class="btn-flat light-green lighten-1">
                            {{ t('signUp.submit') }}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <router-link to="/login">
                            <div class="btn-flat waves-effect waves-green">
                                {{ t('signUp.alreadyHaveAccount') }}
                            </div>
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import UserRequester from '../classes/UserRequester';

export default {
    name: 'Register',
    setup() {
        const i18n = useI18n();
        const t = i18n.t;
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            newUser: {
                username: '',
                password: '',
                email: '',
                language: store?.state?.currentLanguage.id
            },
            visibilityIcon: 'visibility',
            passInputType: 'password'
        });
        const togglePasswordVisibility = async () => {
            if (state.visibilityIcon === 'visibility') {
                state.visibilityIcon = 'visibility_off';
                state.passInputType = 'text';
            } else {
                state.visibilityIcon = 'visibility';
                state.passInputType = 'password';
            }
        };
        const registerUser = async () => {
            try {
                await UserRequester.register(state.newUser);
                M.toast({
                    html: t('signUp.messages.signupSuccess'),
                    classes: 'green'
                });
                router.push('login');
            } catch (err) {
                console.log(err);
                M.toast({
                    html: err.msg || t('signUp.errors.signupFailed'),
                    classes: 'red'
                });
            }
        };
        return {
            state,
            registerUser,
            togglePasswordVisibility,
            t
        };
    }
};
</script>

<style lang="scss" scoped>
#main {
    text-align: center;
}
.postfix {
    float: right;
    cursor: pointer;
    margin-top: 25px;
    padding-right: 10.5px;
}
.row {
    margin: 0;
}
.btn,
.btn-flat {
    width: 100%;
    text-align: center;
}
img {
    width: clamp(112px, 50%, 512px);
}
</style>