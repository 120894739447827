<template>
    <div :class="slideClass">
        <div class="row">
            <div class="scanner-container">
                <div class="scanner-head">
                    <h4>{{ state.title }}</h4>
                    <div v-if="booksFound.length < 1 && !state.addEmptyBook" class="btn-small btn-flat light-green" @click="toggleScanner()">
                        {{ state.scannerOn ? t('scanner.hideScanner') : t('scanner.showScanner') }}
                    </div>
                    <div v-if="booksFound.length < 1 && !state.addEmptyBook" class="btn-small btn-flat light-green" @click="addEmptyBook()">
                        {{ t('scanner.addBookSkipSearch') }}
                    </div>
                </div>

                <div v-if="booksFound.length > 0 || state.addEmptyBook" class="row">
                    <div class="btn-flat blue waves-effect" @click="goBackToScanner()">
                        <i class="material-icons left">chevron_left</i>{{ t('util.goBack') }}
                    </div>
                </div>

                <div v-if="booksFound.length < 1 && state.scannerOn && !state.addEmptyBook" class="scanner-quagga">
                    <Quagga
                        v-if="!state.eanRequestInProgress"
                        class="scanner"
                        @codeDetected="codeScanned" />
                    <div v-else class="loader-wrapper">
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-blue-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div><div class="gap-patch">
                                    <div class="circle"></div>
                                </div><div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="booksFound.length < 1 && !state.addEmptyBook" class="input-field scanner-input">
                    <input
                        id="ean-input"
                        v-model="input.ean"
                        type="text"
                        name="ean"
                        class="validate"
                        @change="inputEan">
                    <label for="ean-input" class="form-label">ISBN:</label>
                    <div class="btn-flat light-green right" @click="inputEan">
                        <i class="material-icons">search</i>
                    </div>
                </div>
            </div>

            <div class="container">
                <div v-if="ownBooksFound.length > 0" class="row scanner-list">
                    <div class="col s12">
                        <h5>{{ t('scanner.foundInYourLibrary') }}</h5>
                    </div>
                    <BookInList
                        v-for="book in ownBooksFound"
                        :key="book"
                        v-bind="book"
                        @bookSelected="selectBook" />
                </div>
                <div v-if="booksFound.length > 0" class="row scanner-list">
                    <div class="col s12">
                        <h5>{{ t('scanner.foundInPublicSources') }}</h5>
                    </div>
                    <BookInList
                        v-for="book in booksFound"
                        :key="book"
                        v-bind="book"
                        @bookSelected="selectBook" />
                </div>
                <div v-if="state.addEmptyBook" class="row scanner-list">
                    <BookInList v-bind="emptyBook" @bookSelected="selectBook" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import BookInList from '../components/BookInList.vue';
import Quagga from '../components/Quagga.vue';
import BookRequester from '../classes/BookRequester';
import Book from '../classes/Book';
import { useI18n } from 'vue-i18n';

export default {
    name: 'Scanner',
    components: {
        BookInList,
        Quagga
    },
    setup() {
        const route = useRoute();
        console.log(route.params);
        const slideClass = route?.params?.slideClass || '';
        onMounted(() => {
            M.AutoInit();
            state.title = t('scanner.scanOrEnterBarcode');
        });
        const { t } = useI18n();
        const store = useStore();
        const input = reactive({
            ean: ''
        })
        const state = reactive({
            title: t('scanner.scanOrEnterBarcode'),
            scannerOn: true,
            eanRequestInProgress: false,
            addEmptyBook: false
        })
        const booksFound = reactive([]);
        const ownBooksFound = reactive([]);
        const emptyBook = {};

        const codeScanned = async (code) => {
            if (state.eanRequestInProgress) return;
            input.ean = code;
            await inputEan();
        }
        const goBackToScanner = () => {
            booksFound.splice(0, booksFound.length);
            ownBooksFound.splice(0, ownBooksFound.length);
            input.ean = '';
            state.addEmptyBook = false;
            state.title = t('scanner.scanOrEnterBarcode');
        }
        const toggleScanner = () => {
            state.scannerOn = !state.scannerOn;
        }
        const addEmptyBook = () => {
            state.addEmptyBook = true;
            state.title = t('scanner.addEmptyBook');
        }
        const inputEan = async () => {
            try {
                if (state.eanRequestInProgress || !input.ean || input.ean === '') return;
                state.eanRequestInProgress = true;
                M.toast({ html: t('scanner.searching'), displayLength: 1000 });
                booksFound.splice(0, booksFound.length);

                const books = await BookRequester.searchISBN(input.ean);

                if (!books || !books.found || !books.own || ( books.found.length < 1 && books.own.length < 1)) throw { msg: t('scanner.errors.noBooksFound') }
                books.found.forEach(book => booksFound.push(book));
                books.own.forEach(book => ownBooksFound.push(Object.assign(book, { isOwn: true })));

                state.title = t('scanner.searchResultsFor') + ' ' + input.ean;
                state.eanRequestInProgress = false
            } catch (err) {
                state.eanRequestInProgress = false
                M.toast({
                    html: err.msg || t('errorsGeneral.errorOccured'),
                    classes: 'red'
                })
            }
        }
        const selectBook = async(book) => {
            if(book.isOwn) return selectOwnBook(book);
            let newBook = await BookRequester.addBook(book);
            if(!newBook.title) {
                return M.toast({
                    html: t('scanner.errors.addingBookFailed'),
                    classes: 'red'
                })
            }
            store.dispatch('Book/addBook', new Book(newBook));
            M.toast({
                html: t('scanner.messages.addedBook'),
                classes: 'green'
            })
            goBackToScanner();
        }
        const selectOwnBook = async(book) => {
            book.copies++;
            let res = await BookRequester.updateBook(book);
            if(!res.title) {
                return M.toast({
                    html: t('scanner.errors.addingBookFailed'),
                    classes: 'red'
                })
            }
            store.dispatch('Book/updateBook', res);
            M.toast({
                html: t('scanner.messages.addedBookCopy'),
                classes: 'green'
            })
            goBackToScanner();
        }
        return {
            input,
            state,
            booksFound,
            ownBooksFound,
            emptyBook,
            slideClass,
            codeScanned,
            goBackToScanner,
            toggleScanner,
            addEmptyBook,
            inputEan,
            selectBook,
            t
        }
    }
}
</script>

<style lang="scss" scoped>
h5 {
    margin-top: 4px;
}
.btn-small {
    margin-bottom: 10px;
    margin-right: 4px;
}
.scanner-container {
    width: clamp(240px, 95vw, 640px);
    margin: auto;
    margin-top: 20px;
}
#ean-input {
    width: 90%;
}
@media screen and (max-width: 600px) {
    #ean-input {
        width: 80%;
    }
}
.loader-wrapper {
    padding: 50px 0 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>