<template>
    <div class="col s12 m6 xl4">
        <div class="card">
            <div class="card-image center-align">
                <img :src="img">
            </div>
            <div class="card-content book-properties">
                <div class="book-property">
                    <i v-if="!bookData.title" class="material-icons yellow-text text-darken-1">error</i>
                    <b>{{ t('book.title') }}:&nbsp;</b>
                    <div
                        :contentEditable="!isOwn"
                        spellcheck="false"
                        :class="{editable: !isOwn}"
                        @input="updateEditable('title', $event)">
                        {{ input.title }}
                    </div>
                </div>
                <div class="book-property">
                    <i v-if="!bookData.authors" class="material-icons yellow-text text-darken-1">error</i>
                    <b>{{ t('book.authors') }}:&nbsp;</b>
                    <div
                        :contentEditable="!isOwn"
                        spellcheck="false"
                        :class="{editable: !isOwn}"
                        @input="updateEditable('authors', $event)">
                        {{ input.authors }}
                    </div>
                </div>
                <div class="book-property">
                    <i v-if="!bookData.isbn10" class="material-icons yellow-text text-darken-1">error</i>
                    <b>ISBN10:&nbsp;</b>
                    <div
                        :contentEditable="!isOwn"
                        spellcheck="false"
                        :class="{editable: !isOwn}"
                        @input="updateEditable('isbn10', $event)">
                        {{ input.isbn10 }}
                    </div>
                </div>
                <div class="book-property">
                    <i v-if="!bookData.isbn13" class="material-icons yellow-text text-darken-1">error</i>
                    <b>ISBN13:&nbsp;</b>
                    <div
                        :contentEditable="!isOwn"
                        spellcheck="false"
                        :class="{editable: !isOwn}"
                        @input="updateEditable('isbn13', $event)">
                        {{ input.isbn13 }}
                    </div>
                </div>
                <div class="book-property">
                    <i v-if="!bookData.publisher" class="material-icons yellow-text text-darken-1">error</i>
                    <b>{{ t('book.publisher') }}:&nbsp;</b>
                    <div
                        :contentEditable="!isOwn"
                        spellcheck="false"
                        :class="{editable: !isOwn}"
                        @input="updateEditable('publisher', $event)">
                        {{ input.publisher }}
                    </div>
                </div>
                <div class="book-property">
                    <i v-if="!bookData.publishDate" class="material-icons yellow-text text-darken-1">error</i>
                    <b>{{ t('book.publishDate') }}:&nbsp;</b>
                    <div
                        :contentEditable="!isOwn"
                        spellcheck="false"
                        :class="{editable: !isOwn}"
                        @input="updateEditable('publishDate', $event)">
                        {{ input.publishDate }}
                    </div>
                </div>
            </div>
            <div class="card-action">
                <a class="green-text" @click="selectBook()">
                    <span v-if="isOwn">{{ t('util.addCopy') }}</span>
                    <span v-else>{{ t('util.add') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    name: "BookInList",
    props: {
        id: { type: Number || String },
        title: { type: String, default: ''},
        isbn10: { type: String, default: ''},
        isbn13: { type: String, default: ''},
        authors: { type: String, default: ''},
        publisher: { type: String, default: ''},
        publishDate: { type: String, default: ''},
        img: { type: String, default: ''},
        copies: { type: Number || String, default: 0},
        isOwn: Boolean
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            editMode: false
        })
        const input = reactive({
            title: props.title,
            authors: props.authors,
            isbn10: props.isbn10,
            isbn13: props.isbn13,
            publisher: props.publisher,
            publishDate: props.publishDate,
            img: props.img
        })
        const bookData = reactive({
            id: props.id,
            title: props.title,
            authors: props.authors,
            isbn10: props.isbn10,
            isbn13: props.isbn13,
            publisher: props.publisher,
            publishDate: props.publishDate,
            img: props.img,
            isOwn: props.isOwn,
            copies: props.copies
        })
        const editBook = () => {
            state.editMode = !state.editMode;
        }
        const selectBook = () => {
            emit('bookSelected', {...bookData});
        }
        const updateEditable = (property, event) => {
            bookData[property] = event.target.innerHTML;
        }
        return {
            state,
            input,
            bookData,
            editBook,
            selectBook,
            updateEditable,
            t
        }
    }
};
</script>

<style lang="scss" scoped>
.card {
    .card-image {
        display: flex;
        justify-content: center;
        img {
            max-width: 128px;
            border-radius: 2px;
        }
    }
    .card-content {
		font-size: 16px;
        padding: 16px;
        i {
            font-size: 16px;
        }
        .book-property {
            line-height: 1.2;
            margin-bottom: 10px;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            .editable {
                border-bottom: solid 1px #DDD;
                transition: all .3s ease;
                cursor: pointer;
                flex-grow: 1;
                margin-left: 4px;
                &:focus {
                    outline: none;
                    border-color: #21a930;
                }
            }
        }
    }
    .card-action {
        display: flex;
        flex-direction: row-reverse;
        a {
            cursor: pointer;
        }
    }
}
</style>