class Book {
    constructor(object) {
        this.id = object.id;
        this.title = object.title;
        this.isbn13 = object.isbn13;
        this.isbn10 = object.isbn10;
        this.authors = object.authors;
        this.publisher = object.publisher;
        this.publishDate = object.publishDate;
		this.img = object.img;
		this.copies = object.copies;
		this.timestampAddedAt = object.timestampAddedAt;
	}
	updateProps(object) {
        this.title = object.title;
        this.isbn13 = object.isbn13;
        this.isbn10 = object.isbn10;
        this.authors = object.authors;
        this.publisher = object.publisher;
        this.publishDate = object.publishDate;
		this.img = object.img;
		this.copies = object.copies;
	}
	getExport() {
		return {
			title: this.title,
			isbn13: this.isbn13,
			isbn10: this.isbn10,
			authors: this.authors, 
			publisher: this.publisher,
			publishDate: this.publishDate,
			copies: this.copies,
			img: this.img
		}
	}
	getCopy() {
		return {...this}
	}
}

export default Book;