<template>
    <div id="interactive" class="viewport scanner">
        <video />
        <div class="noCameraAccess">{{ t('scanner.noCameraAccess') }}</div>
        <canvas id="quaggaCanvas" class="drawingBuffer" />
        <canvas id="drawingBuffer"/>
    </div>
</template>

<script>
import Quagga from 'quagga';
import { useI18n } from 'vue-i18n';
import { onMounted, onUnmounted } from '@vue/runtime-core';

export default {
    name: 'QuaggaScanner',
    setup(props, { emit }) {
        const { t } = useI18n();
        let width, height;
        const quaggaState = {
            inputStream: {
                type: 'LiveStream',
                constraints: {
                    facingMode: 'environment'
                },
                area: {
                    top: "35%",
                    right: "25%",
                    left: "25%",
                    bottom: "35%"
                }
            },
            numOfWorkers: navigator.hardwareConcurrency || 2,
            frequency: 20,
            decoder: {
                readers: ['ean_reader'],
                multiple: false
            },
            locate: false
        }

        let onDetected = (result) => {
            let drawingCtx = Quagga.canvas.ctx.overlay;
            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(
                    result.line, { x: 'x', y: 'y' },
                    drawingCtx, { color: 'red', lineWidth: 3 }
                );
            }
            setTimeout(() => {
                drawingCtx.clearRect(
                    0,
                    0,
                    width,
                    height
                );
            }, 100)
            emit('codeDetected', result.codeResult.code)
        }

        onMounted(() => {
            Quagga.init(quaggaState, (err) => {
                if (err) return console.error(err);
                Quagga.start();
                let video = document.querySelector('video');
                width = video.scrollWidth || 0;
                height = video.scrollHeight || 0;

                let x_left = width*0.25;
                let x_right = width*0.75;
                let y_upper = height*0.35;
                let y_lower = height*0.65;
                let box = [ [ x_left, y_upper ], [ x_right, y_upper ], [ x_right, y_lower ], [ x_left, y_lower ], [ x_left, y_upper] ];

                let canvas = document.getElementById("drawingBuffer");
                canvas.width = video.scrollWidth;
                canvas.height = video.scrollHeight;
                let ctx = canvas.getContext("2d");
                ctx.beginPath();
                ctx.strokeStyle = "#2196f3";

                for(let i = 0; i < box.length-1; i++) {
                    ctx.moveTo(box[i][0], box[i][1]);
                    ctx.lineTo(box[i+1][0], box[i+1][1]);
                }
                ctx.stroke();
            });

            Quagga.onDetected(onDetected);
        })

        onUnmounted(() => {
            Quagga.offDetected(onDetected);
            Quagga.stop();
        })

        return {
            t,
            quaggaState
        };
    }
};
</script>

<style scoped lang="scss">
.viewport {
    position: relative;
    .noCameraAccess,
    canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    video {
        width: 100%;
    }
    video[muted=true] ~ .noCameraAccess {
        display: none;
    }
}
</style>