import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setupI18n } from './lang';

import './sw';
import 'materialize-css/dist/css/materialize.min.css'
import './styles/main.scss';
import * as en from './lang/en.json';

let app = createApp(App);

const i18n = setupI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en'
})

i18n.global.setLocaleMessage('en', en.default);

app.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = (event) => {
            console.log('clc', event);
            if (!(el === event.target || el.contains(event.target)))
            binding.value(event, el);
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

app.use(store).use(router).use(i18n).mount('#app');
