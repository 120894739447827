<template>
    <li class="collection-item avatar">
        <img
            v-if="book.img"
            :src="book.img"
            class="bookImg"
            async>
        
        <div v-if="shortView" class="row book">
            <div class="col s12 m3 book-attribute title">{{ book.title }}</div>
            <div class="col s12 m3 book-attribute"><b v-if="book.authors" class="hide-on-med-and-up">{{ t('book.authors') }}</b> {{ book.authors }}</div>
            <div class="col s12 m3 book-attribute"><b v-if="book.publisher" class="hide-on-med-and-up">{{ t('book.publisher') }}</b> {{ book.publisher }}</div>
            <div class="col s12 m2 book-attribute"><b v-if="book.publishDate" class="hide-on-med-and-up">{{ t('book.publishDate') }}</b> {{ book.publishDate }}</div>
            <div class="col s12 m1 book-attribute"><b v-if="book.copies" class="hide-on-med-and-up">{{ t('book.copies') }}</b> {{ book.copies }}</div>
        </div>

        <div v-else class="row book">
            <div class="col s12 m3 l3 xl4 book-attribute title">{{ book.title }}</div>
            <div class="col s12 m2 l2 xl2 book-attribute"><b v-if="book.authors" class="hide-on-med-and-up">{{ t('book.authors') }}</b> {{ book.authors }}</div>
            <div class="col s12 m2 l1 xl1 book-attribute"><b v-if="book.publisher" class="hide-on-med-and-up">{{ t('book.publisher') }}</b> {{ book.publisher }}</div>
            <div class="col s12 m1 l1 xl1 book-attribute"><b v-if="book.publishDate" class="hide-on-med-and-up">{{ t('book.publishDate') }}</b> {{ book.publishDate }}</div>
            <div class="col s12 m3 l2 xl2 book-attribute"><b v-if="book.isbn13" class="hide-on-med-and-up">ISBN13</b> {{ book.isbn13 }}</div>
            <div class="col s12 m0 l2 xl1 book-attribute hide-on-med-and-down"><b v-if="book.isbn10" class="hide-on-med-and-up">ISBN10</b> {{ book.isbn10 }}</div>
            <div class="col s12 m1 l1 xl1 book-attribute"><b v-if="book.copies" class="hide-on-med-and-up">{{ t('book.copies') }}</b> {{ book.copies }}</div>
        </div>

        <div class="secondary-content submenu">
            <div class="btn-flat waves-effect waves-yellow modal-trigger" :data-target="editBookModalId">
                <i class="material-icons">edit</i>
            </div>
            <div class="btn-flat waves-effect waves-red modal-trigger" :data-target="deleteBookModalId">
                <i class="material-icons">delete</i>
            </div>
        </div>
        
        <div :id="editBookModalId" class="modal">
            <div class="modal-content">
                <h4>{{ t('book.editBook') }}</h4>
                <div class="input-field col s12">
                    <label for="title">{{ t('book.title') }}</label>
                    <input
                        id="title"
                        v-model="state.bookCopy.title"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="authors">{{ t('book.authors') }}</label>
                    <input
                        id="authors"
                        v-model="state.bookCopy.authors"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="isbn10">ISBN10</label>
                    <input
                        id="isbn10"
                        v-model="state.bookCopy.isbn10"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="isbn13">ISBN13</label>
                    <input
                        id="isbn13"
                        v-model="state.bookCopy.isbn13"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="publisher">{{ t('book.publisher') }}</label>
                    <input
                        id="publisher"
                        v-model="state.bookCopy.publisher"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="publishDate">{{ t('book.publishDate') }}</label>
                    <input
                        id="publishDate"
                        v-model="state.bookCopy.publishDate"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="publishDate">{{ t('book.coverImg') }}</label>
                    <input
                        id="publishDate"
                        v-model="state.bookCopy.img"
                        type="text">
                </div>
                <div class="input-field col s12">
                    <label for="publishDate">{{ t('book.copies') }}</label>
                    <input
                        id="publishDate"
                        v-model="state.bookCopy.copies"
                        type="number">
                </div>
            </div>
            <div class="modal-footer">
                <button class="waves-effect waves-green btn-flat light-green modal-close" @click="updateBook()">{{ t('util.save') }}</button>
                <button class="waves-effect waves-green btn-flat red modal-close">{{ t('util.close') }}</button>
            </div>
        </div>

        <div :id="deleteBookModalId" class="modal">
            <div class="modal-content">
                <h4>{{ t('book.areYouSure') }} "{{ state.book.title }}" {{ t('book.fromLibrary') }}</h4>
            </div>
            <div class="modal-footer">
                <button class="waves-effect waves-green btn-flat light-green modal-close" @click="deleteBook()">{{ t('util.confirm') }}</button>
                <button class="waves-effect waves-green btn-flat red modal-close">{{ t('util.cancel') }}</button>
            </div>
        </div>
    </li>
</template>

<script lang="js">
import { reactive, onMounted } from "vue";
import { useStore } from "vuex";
import BookRequester from "../classes/BookRequester.js";
import Book from "../classes/Book.js";
import { useI18n } from 'vue-i18n';

export default {
    name: "BookInLibrary",
    props: {
        book: Book,
        shortView: Boolean
    },
    emits: ['bookDeleted', 'bookUpdated'],
    setup(props, { emit }) {
        const store = useStore();
        const { t } = useI18n();
        const state = reactive({
            fullView: false,
            editBookModal: false,
            deleteBookModal: false,
            book: props.book,
            bookCopy: props.book.getCopy()
        })
        const editBookModalId = 'edit-book-modal' + state.book.id;
        const deleteBookModalId = 'delete-book-modal' + state.book.id;
        onMounted(() => {
            let modals = document.querySelectorAll('.modal');
            M.Modal.init(modals, {
                preventScrolling: true,
                onOpenEnd: () => M.updateTextFields(),
                onOpenStart: () => {
                    let app = document.getElementById('app').firstChild;
                    console.log(app);
                    app.style.position = 'fixed';
                    app.style.top = `-${app.scrollY}px`;
                },
                onCloseStart: () => {
                    let app = document.getElementById('app').firstChild;
                    const scrollY = app.style.top;
                    app.style.position = '';
                    app.style.top = '';
                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                } });
        })
        const updateBook = async() => {
            let res = await BookRequester.updateBook(state.bookCopy);
            console.log(res);
            if(!res.title) {
                return M.toast({
                    html: t('book.errors.updateBookFailed'),
                    classes: 'red'
                })
            }
            store.dispatch('Book/updateBook', state.bookCopy);
            M.toast({
                html: t('book.messages.bookUpdated'),
                classes: 'green'
            })
            state.editBookModal = false;
            emit('bookUpdated');
        }
        const deleteBook = async() => {
            let res = await BookRequester.deleteBook(state.book);
            if(!res.title) {
                return M.toast({
                    html: t('book.errors.deleteBookFailed'),
                    classes: 'red'
                })
            }
            store.dispatch('Book/deleteBook', state.book);
            M.toast({
                html: t('book.messages.bookDeleted'),
                classes: 'green'
            })
            state.deleteBookModal = false;
            emit('bookDeleted');
        }
        return {
            state,
            updateBook,
            deleteBook,
            editBookModalId,
            deleteBookModalId,
            t
        }
    }
};
</script>

<style lang="scss" scoped>
.collection .collection-item.avatar {
    min-height: 100px;
}
.bookImg {
    position: absolute;
    left: 8px;
    width: 54px;
}
.collection-item.avatar {
    .secondary-content.submenu {
        top: unset;
        bottom: 10px;
        visibility: hidden;
        opacity: 0;
        transition: opacity .5s;
    }
    &:hover {
        background-color: #F5F5F9;
        .submenu {
            visibility: visible;
            opacity: 100;
            transition: opacity .5s;
        }
    }
    &:last-of-type {
        margin-bottom: 32px;
    }
}
</style>

