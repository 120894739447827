import Book from './Book';
import Requester from './Requester';

class BookRequester extends Requester {

	/**
	 * @param { Number | String } isbn 
	 */
	static async searchISBN(isbn) {
		try {
			const books = await this.getJsonSecure('/api/book/find/' + isbn);
			return books;
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	static async fetchBooks() {
		try {
			const resBody = await this.getJsonSecure('/api/user/library');
			return resBody.map(book => new Book(book));
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	static async fetchRecentBooks() {
		try {
			const resBody = await this.getJsonSecure('/api/user/library/recent');
			return resBody.map(book => new Book(book));
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	static async getLibraryLength() {
		try {
			const resBody = await this.getJsonSecure('/api/user/library/length');
			return resBody.nBooks;
		} catch (error) {
			console.log(error);
			return 0;
		}
	}

	static async searchBooks(search) {
		try {
			const resBody = await this.getJsonSecure('/api/book/search/' + search);
			return resBody.map(book => new Book(book));
		} catch (error) {
			console.log(error);
			return [];
		}
	}
	
	/**
	 * @param {Book} book
	 */
	static async addBook(book) {
		try {
			const res = await this.postJsonSecure('/api/book', book);
			return res;
		} catch (error) {
			console.log(error);
			return {};
		}
	}

	/**
	 * @param {Book} book
	 */
	static async deleteBook(book) {
		try {
			const res = await this.deleteSecure('/api/book/' + book.id);
			return res;
		} catch (error) {
			console.log(error);
			return {};
		}
	}

	/**
	 * @param {Book} book
	 */
	static async updateBook(book) {
		try {
			const res = await this.putJsonSecure('/api/book/' + book.id, book);
			return res;
		} catch (error) {
			console.log(error);
			return {};
		}
	}
}

export default BookRequester