export const UserModule = {
	namespaced: true,

	state: {
		user: null
	},

	mutations: {
		SET_USER(state, user) {
			state.user = user;
		},
		SET_TOKEN(state, token) {
			if(state.user) state.user.token = token;
		},
		SET_REISSUE_TOKEN(state, token) {
			if(state.user) state.user.reissueToken = token;
		},
		UPDATE_USER_IN_LOCAL_STORAGE(state) {
			localStorage.setItem('user', JSON.stringify(state.user));
		},
		LOG_OUT(state) {
			if(state) state.user = null;
			localStorage.clear();
		}
	},

	actions: {
		setUser({commit}, user) {
			commit('SET_USER', user);
		},
		setToken({commit}, token) {
			commit('SET_TOKEN', token);
		},
		setReissueToken({commit}, token) {
			commit('SET_REISSUE_TOKEN', token);
		},
		updateUserInLocalStorage({commit}) {
			commit('UPDATE_USER_IN_LOCAL_STORAGE');
		},
		logOut({commit}) {
			commit('LOG_OUT');
		}
	}
}