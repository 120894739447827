export const BookModule = {
    namespaced: true,

    state: {
        books: []
    },

    mutations: {
        SET_BOOKS(state, books) {
            state.books = books;
        },
        ADD_BOOK(state, book) {
            state.books.push(book);
        },
        UPDATE_BOOK(state, book) {
            let b = state.books.find(b => b.id === book.id);
            if(b) b.updateProps(book);
        },
        DELETE_BOOK(state, book) {
            state.books = state.books.filter(b => b.id !== book.id);
        }
    },

    actions: {
        setBooks({commit}, books) {
            commit('SET_BOOKS', books);
        },
        addBook({commit}, book) {
            commit('ADD_BOOK', book);
        },
        updateBook({commit}, book) {
            commit('UPDATE_BOOK', book)
        },
        deleteBook({commit}, books) {
            commit('DELETE_BOOK', books)
        }
    }
}