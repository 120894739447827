import router from "@/router";
import store from "@/store";

class Requester {
	static async getJsonSecure(url) {
		try {
			const res = await fetch(url, {
				headers: {
					Authorization: store.state.User.user.token
				}
			})
			if (!res.ok) {
				if(res.status === 401) {
					await this.reissueToken();
					return await this.getJsonSecure(url);
				}
				let text = await res.text();
				throw { msg: text };
			}
			const resBody = await res.json();
			return resBody;
		} catch (err) {
			// TODO: Error handling
			throw err;
		}
	}
	static async postJsonSecure(url, body) {
		try {
			const res = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: store.state.User.user.token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			if (!res.ok) {
				if(res.status === 401) {
					await this.reissueToken()
					return await this.postJsonSecure(url, body)
				}
				let text = await res.text();
				throw { msg: text }
			}
			const resBody = await res.json()
			return resBody
		} catch (err) {
			// TODO: Error handling
			console.error(err);
			throw err;
		}
	}
	static async postJson(url, body) {
		try {
			const res = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			if (!res.ok) {
				let text = await res.text();
				throw { msg: text }
			}
			const resBody = await res.json();
			return resBody;
		} catch (err) {
			// TODO: Error handling
			console.error(err);
			throw err;
		}
	}
	static async putJsonSecure(url, body) {
		try {
			const res = await fetch(url, {
				method: 'PUT',
				headers: {
					Authorization: store.state.User.user.token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
			if (!res.ok) {
				if(res.status === 401) {
					await this.reissueToken()
					return await this.putJsonSecure(url, body)
				}
				let text = await res.text();
				throw { msg: text }
			}
			const resBody = await res.json()
			return resBody
		} catch (err) {
			// TODO: Error handling
			throw err;
		}
	}
	static async deleteSecure(url) {
		try {
			const res = await fetch(url, {
				method: 'DELETE',
				headers: {
					Authorization: store.state.User.user.token,
					'Content-Type': 'application/json'
				}
			})
			if (!res.ok) {
				if(res.status === 401) {
					await this.reissueToken()
					return await this.deleteSecure(url)
				}
				let text = await res.text();
				throw { msg: text }
			}
			const resBody = await res.json()
			return resBody
		} catch (err) {
			// TODO: Error handling
			throw err;
		}
	}
	static async getJson(url) {
		try {
			const res = await fetch(url);
			if (!res.ok) {
				let text = await res.text();
				throw { msg: text }
			}
			const resBody = await res.json()
			return resBody
		} catch (err) {
			// TODO: Error handling
			throw err;
		}
	}
	static async reissueToken() {
		console.log('reissue');
		try {
			let res = await fetch('/api/user/token/reissue', {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: store.state.User.user.reissueToken
				},
				referrer: "no-referrer"
			})
			if(!res.ok) {
				throw { msg: 'Token refreshing failed' }
			}
			let body = await res.json();
			store.dispatch('User/setToken', body.token);
			store.dispatch('User/setReissueToken', body.reissueToken);
			store.dispatch('User/updateUserInLocalStorage');
		}
		catch(err) {
			// TODO: Error handling
			store.dispatch('User/logOut');
			router.push('login')
			throw err;
		}
	}
}

export default Requester