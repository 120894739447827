<template>
    <div class="slider-from-left slide-from-left">
        <div class="row">
            <div class="col s12">
                <h4>{{ t('library.yourLibrary') }}</h4>
            </div>
        </div>

        <div class="search row">
            <div class="input-field col s12 l4">
                <i class="material-icons prefix">search</i>
                <input
                    v-model="state.search"
                    type="text"
                    :placeholder="t('library.search')"
                    @input="search">
            </div>
        </div>

        <ul class="pagination">
            <li :class="{ disabled: state.currentPage === 0 }" @click="prevPage"><a><i class="material-icons clickable">chevron_left</i></a></li>
            <li
                v-for="i in state.pages.length"
                :key="i"
                class="waves-effect"
                :class="{ active: state.currentPage === i-1 }"
                @click="selectPage(i-1)">
                <a>{{ i }}</a>
            </li>
            <li :class="{ disabled: state.currentPage >= state.pages.length-1 }" @click="nextPage"><a><i class="material-icons clickable">chevron_right</i></a></li>
        </ul>

        <div class="row flex">
            <div class="middle">{{ t('library.booksPerPage') }}</div>
            <input
                id="pageSize"
                v-model="state.customPageSize"
                type="number"
                class="validate"
                @input="updatePageSize">
            <div class="btn-flat light-green hide-on-med-and-up modal-trigger" data-target="sort-modal">
                <i class="material-icons">sort</i>
            </div>
        </div>

        <ul class="collection">
            <li class="collection-item avatar head-row hide-on-small-only">
                <div class="row">
                    <div class="col m3 l3 xl4 book-attribute" @click="sortBy('title')">
                        {{ t('book.title') }}
                        <i v-if="state.sortBy === 'title'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                    <div class="col m2 l2 xl2 book-attribute" @click="sortBy('authors')">
                        {{ t('book.authors') }}
                        <i v-if="state.sortBy === 'authors'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                    <div class="col m2 l1 xl1 book-attribute" @click="sortBy('publisher')">
                        {{ t('book.publisher') }}
                        <i v-if="state.sortBy === 'publisher'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                    <div class="col m2 l1 xl1 book-attribute" @click="sortBy('publishDate')">
                        {{ t('book.publishDate') }}
                        <i v-if="state.sortBy === 'publishDate'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                    <div class="col m2 l2 xl2 book-attribute" @click="sortBy('isbn13')">
                        ISBN13
                        <i v-if="state.sortBy === 'isbn13'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                    <div class="col m0 l2 xl1 book-attribute hide-on-med-only" @click="sortBy('isbn10')">
                        ISBN10
                        <i v-if="state.sortBy === 'isbn10'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                    <div class="col m1 l1 xl1 book-attribute" @click="sortBy('copies')">
                        {{ t('book.copies') }}
                        <i v-if="state.sortBy === 'copies'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                    </div>
                </div>
            </li>
            <BookInLibrary
                v-for="book in state.pages[state.currentPage]"
                :key="book.id"
                v-bind="{ book: book }" />
        </ul>

        <div id="sort-modal" class="modal">
            <div class="modal-content">
                <h4>{{ t('library.sortBy') }}</h4>
                <div class="row">
                    <div class="col s6">
                        <div class="btn-flat green lighten-4" @click="sortBy('title')">
                            {{ t('book.title') }}
                            <i v-if="state.sortBy === 'title'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                    <div class="col s6">
                        <div class="btn-flat green lighten-4" @click="sortBy('authors')">
                            {{ t('book.authors') }}
                            <i v-if="state.sortBy === 'authors'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s6">
                        <div class="btn-flat green lighten-4" @click="sortBy('publisher')">
                            {{ t('book.publisher') }}
                            <i v-if="state.sortBy === 'publisher'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                    <div class="col s6">
                        <div class="btn-flat green lighten-4" @click="sortBy('copies')">
                            {{ t('book.copies') }}
                            <i v-if="state.sortBy === 'copies'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s6">
                        <div class="btn-flat green lighten-4" @click="sortBy('isbn13')">
                            ISBN13
                            <i v-if="state.sortBy === 'isbn13'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                    <div class="col s6">
                        <div class="btn-flat green lighten-4" @click="sortBy('isbn10')">
                            ISBN10
                            <i v-if="state.sortBy === 'isbn10'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div class="btn-flat green lighten-4" @click="sortBy('publishDate')">
                            {{ t('book.publishDateLong') }}
                            <i v-if="state.sortBy === 'publishDate'" :class="{ reversed : state.sortReversed }" class="material-icons">expand_more</i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="waves-effect waves-green btn-flat light-green modal-close">{{ t('util.close') }}</a>
            </div>
        </div>

    </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import { useStore } from "vuex";
import BookRequester from '../classes/BookRequester';
import BookInLibrary from "../components/BookInLibrary";

export default {
    name: 'Library',
    components: {
        BookInLibrary
    },
    setup() {
        onMounted(() => {
            let modals = document.querySelectorAll('.modal');
            M.Modal.init(modals, { preventScrolling: true, onOpenEnd: () => M.updateTextFields() });
        })
        const { t } = useI18n();
        const chunkArray = (array, chunkSize) => {
            let chunks = [];
            for (let i = 0; i<array.length; i+=chunkSize)
                chunks.push(array.slice(i, i+chunkSize));
            return chunks;
        }
        const prepareBooks = (bookArray) => {
            let sortedBooks = bookArray.sort((a, b) => {
                if(a[state.sortBy] > b[state.sortBy]) return state.sortReversed ? -1 : 1;
                if(a[state.sortBy] < b[state.sortBy]) return state.sortReversed ? 1 : -1;
                return 0;
            })
            state.nBooks = bookArray.length;
            return chunkArray(sortedBooks, state.pageSize);
        }
        const store = useStore();
        const books = computed(() => prepareBooks(store.state.Book.books));
        const state = reactive({
            search: '',
            pages: books,
            currentPage: 0,
            sortBy: 'title',
            sortReversed: false,
            nBooks: 0,
            pageSize: 25,
            customPageSize: 25,
            sortModal: false
        })
        const search = async() => {
            if(!state.search) return state.pages = false;
            store.dispatch('Book/setBooks', await BookRequester.searchBooks(state.search));
            state.currentPage = 0;
        }
        const nextPage = () => {
            state.currentPage++;
        }
        const prevPage = () => {
            if(state.currentPage > 0) state.currentPage--;
        }
        const selectPage = (n) => {
            state.currentPage = n;
        }
        const sortBy = (property) => {
            if(state.sortBy === property) return state.sortReversed = !state.sortReversed;
            state.sortBy = property;
            state.sortReversed = false;
        }
        const updatePageSize = () => {
            // protect from not numeric input as it breaks the view
            if(+(state.customPageSize) <= 0) return;
            state.pageSize = +(state.customPageSize);
        }
        return {
            state,
            search,
            nextPage,
            prevPage,
            selectPage,
            sortBy,
            updatePageSize,
            t
        }
    }
}
</script>

<style lang="scss" scoped>
.collection {
    .collection-item.avatar.head-row {
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 0px;
        user-select: none;
        .row {
            margin: 0;
            .col.book-attribute {
                vertical-align: middle;
                line-height: 10px;
                cursor: pointer;
                border-radius: 4px;
                padding: 10px 4px;
                i {
                    line-height: 0;
                    vertical-align: middle;
                }
                &:hover {
                    background-color: #e5f7cf;
                }
            }
        }
    }
}
.input-field {
    margin: 0;
}
input::placeholder {
    color: #666;
}
.pagination li.active {
    background-color: #8bc34a;
}
.disabled {
    user-select: none;
    pointer-events: none;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    color: #666;
    * {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
    input {
        color: #666;
        max-width: 50px;
    }
}
.middle {
    line-height: 50px;
}
i {
    transition: all .2s;
    &.reversed {
        transform: rotate(180deg);
        transition: all .2s;
    }
}
.clickable {
    cursor: pointer;
}
</style>