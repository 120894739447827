import { register } from 'register-service-worker';

const VERSION = '0.0.1';
const CACHE_NAME = 'hometheca-cache-v1';
const urlsToCache = [
    '/'
];

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated() {
            console.log('New content is available; please refresh.');
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
}

self.addEventListener('install', (event) => {
    event.waitUntil(
        caches.open(CACHE_NAME)
        .then((cache) => cache.addAll(urlsToCache))
    );
});

self.addEventListener('activate', event => {
    console.log("activated");
})

self.addEventListener('fetch', event => {
    if (event.request.method === "POST") return false;
    if (event.request.method === "DELETE") return false;
    if (event.request.method === "PATCH") return false;
    if (event.request.url.includes('reissue')) return false;
    else {
        event.respondWith(
            caches.open(CACHE_NAME).then(cache => {
                return cache.match(event.request).then(response => {

                    if (response) {
                        fetch(event.request).then(netresponse => {
                            cache.put(event.request, netresponse.clone());
                        })
                    }

                    return response || fetch(event.request).then(netresponse => {
                        cache.put(event.request, netresponse.clone());
                        return netresponse;
                    });
                });
            })
        );
    }
});

self.addEventListener('push', async event => {
    console.log(event);
    let data = await event.data.json();
    console.log(data);
    const body = data.msg;
    const icon = "/images/favicon.png";
    const tag = "Hometheca";
    self.registration.showNotification(data.title, {
        body: body,
        icon: icon,
        tag: tag
    })
})