<template>
    <div id="main" class="container">
        <div class="row">
            <div class="col s12">
                <img
                    alt="Hometheca Logo"
                    src="@/assets/logo_512x512.png">
            </div>
        </div>

        <div v-if="state.forgotPasswordForm" class="row">
            <h5>{{ t('signIn.headingForgotPassword') }}</h5>
            <div class="col s12">{{ t('signIn.textForgotPassword') }}</div>
            <form class="col s12" @submit.prevent="forgotPassword()">
                <div class="row">
                    <div class="input-field col s12">
                        <input
                            id="email-forgot"
                            v-model="state.forgotPasswordEmail"
                            type="email"
                            class="validate">
                        <label for="email-forgot">{{ t('signUp.email') }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <button
                            id="submit-forgot"
                            type="submit"
                            class="btn-flat light-green lighten-1">
                            {{ t('signIn.submitForgotPassword') }}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div class="btn-flat waves-effect waves-green" @click="state.forgotPasswordForm = false">
                            {{ t('util.goBack') }}
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div v-else-if="state.resetPasswordToken" class="row">
            <h5>{{ t('signIn.headingForgotPassword') }}</h5>
            <div>{{ t('signIn.textResetPassword') }}</div>
            <form class="col s12" @submit.prevent="resetPassword()">
                <div class="row">
                    <div class="input-field col s10 m11">
                        <input
                            id="password-reset"
                            v-model="state.newPassword"
                            :type="state.passInputType">
                        <label for="password-reset">{{ t('signIn.newPassword') }}</label>
                    </div>
                    <i
                        class="material-icons postfix no-select"
                        @click="togglePasswordVisibility()">
                        {{ state.visibilityIcon }}
                    </i>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <button
                            id="submit-reset"
                            type="submit"
                            class="btn-flat light-green lighten-1">
                            {{ t('util.save') }}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div class="btn-flat waves-effect waves-green" @click="goToLogin">
                            {{ t('util.goBack') }}
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div v-else class="row">
            <h5>{{ t('signIn.heading') }}</h5>
            <form class="col s12" @submit.prevent="login()">
                <div class="row">
                    <div class="input-field col s12">
                        <input
                            id="username"
                            v-model="state.username"
                            type="text">
                        <label for="username">{{ t('signUp.username') }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s10 m11">
                        <input
                            id="password"
                            v-model="state.password"
                            :type="state.passInputType">
                        <label for="password">{{ t('signUp.password') }}</label>
                    </div>
                    <i
                        class="material-icons postfix no-select"
                        @click="togglePasswordVisibility()">
                        {{ state.visibilityIcon }}
                    </i>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <button
                            id="submit"
                            type="submit"
                            class="btn-flat light-green lighten-1">
                            {{ t('signIn.submit') }}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <router-link to="/register">
                            <div class="btn-flat waves-effect waves-green">
                                {{ t('signIn.createNewAccount') }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <a class="btn-flat waves-effect waves-green" @click="state.forgotPasswordForm = true">{{ t('signIn.forgotPassword') }}</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, onUpdated } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import User from "../classes/User";
import Requester from '../classes/Requester';
import BookRequester from '../classes/BookRequester';
import { setI18nLanguage, loadLocaleMessages } from '../lang'

export default {
    name: 'Login',
    props: {
        token: { type: String, default: '' }
    },
    setup() {
        const i18n = useI18n();
        const { t } = i18n;
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const state = reactive({
            username: '',
            password: '',
            visibilityIcon: 'visibility',
            passInputType: 'password',
            forgotPasswordFrom: false,
            forgotPasswordEmail: '',
            resetPasswordToken: route?.params?.token || '',
            newPassword: ''
        });
        onUpdated(() => {
            M.updateTextFields();
        })
        const togglePasswordVisibility = async () => {
            if (state.visibilityIcon === 'visibility') {
                state.visibilityIcon = 'visibility_off';
                state.passInputType = 'text';
            } else {
                state.visibilityIcon = 'visibility';
                state.passInputType = 'password';
            }
        };
        const login = async () => {
            try {
                let body = {
                    username: state.username,
                    password: state.password
                }
                let loginData = await Requester.postJson('/api/user/login', body)
                let user = new User(loginData.user);
                localStorage.setItem('user', JSON.stringify(user));
                store.dispatch('User/setUser', user);
                await loadLocaleMessages(i18n, user.locale);
                setI18nLanguage(i18n, user.locale);
                M.toast({
                    html: t('signIn.messages.loginSuccessful'),
                    displayLength: 1000,
                    classes: 'green'
                });
                router.push('home');
                try {
                    let books = await BookRequester.fetchBooks();
                    store.dispatch('Book/setBooks', books);
                } catch (err) {
                    console.log(err);
                    M.toast({ html: t('library.errors.libraryLoadFailed') })
                }
            } catch (err) {
                M.toast({
                    html: err.msg ||  t('errorsGeneral.errorOccured'),
                    classes: 'red'
                });
            }
        }
        const forgotPassword = async() => {
            if(!state.forgotPasswordEmail)
                return M.toast({
                    html: t('signUp.emailCantBeEmpty'),
                    classes: 'red'
                });
            try {
                let body = { email: state.forgotPasswordEmail }
                await Requester.postJson('/api/user/password/forgot', body);
                M.toast({
                    html: t('signIn.messages.resetPasswordEmailSent'),
                    classes: 'green'
                });
            } catch (err) {
                M.toast({
                    html: t('errorsGeneral.errorOccured'),
                    classes: 'red'
                });
            }
        }
        const resetPassword = async() => {
            try {
                let body = {
                    token: state.resetPasswordToken,
                    password: state.newPassword
                }
                let res = await Requester.postJson('/api/user/password/reset', body);
                M.toast({
                    html: t('signIn.messages.resetPasswordSuccessful'),
                    classes: 'green'
                });
                goToLogin();
            } catch(err) {
                M.toast({
                    html: t('errorsGeneral.errorOccured'),
                    classes: 'red'
                });   
            }
        }
        const goToLogin = () => {
            state.resetPasswordToken = '';
            state.forgotPasswordFrom = false;
            router.push('/login');
        }
        return {
            state,
            login,
            forgotPassword,
            goToLogin,
            togglePasswordVisibility,
            resetPassword,
            t
        };
    }
};
</script>

<style lang="scss" scoped>
#main {
    text-align: center;
}
.postfix {
    float: right;
    cursor: pointer;
    margin-top: 25px;
    padding-right: 10.5px;
}
.row {
    margin: 5px 0;
}
.btn,
.btn-flat {
    width: 100%;
    text-align: center;
}
img {
    width: clamp(112px, 50%, 512px);
}
</style>