class User {
	constructor(object) {
		this.id = object.id;
		this.username = object.username;
		this.token = object.token;
		this.reissueToken = object.reissueToken;
		this.locale = object.locale;
	}
}

export default User;