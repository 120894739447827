import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

export const SUPPORTED_LOCALES = ['en', 'pl'];

export function setupI18n(options = { locale: 'en' }) {
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

export function setI18nLanguage(i18n, locale) {
    if(i18n.global) i18n.global.locale.value = locale
    else i18n.locale.value = locale
    document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
    const messages = await import(`@/lang/${locale}.json`);    
    if(i18n.global) i18n.global.setLocaleMessage(locale, messages.default);
    else i18n.setLocaleMessage(locale, messages.default);
}