<template>
    <div>
        <!-- <div id="btnAdd" class="btn">Install</div> -->
        <nav id="nav" class="nav hide-on-small-only">
            <div class="nav-wrapper">
                <a class="nav-logo">
                    <router-link to="/index">
                        <img
                            alt="Hometheca Logo"
                            src="@/assets/logo_nav.png"
                            class="responsive-img">
                    </router-link>
                </a>
                <ul v-if="user" class="right">
                    <li>
                        <router-link to="/add">{{ t('navigation.addBook') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/library">{{ t('navigation.library') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/home">{{ t('navigation.start') }}</router-link>
                    </li>
                </ul>
                <ul v-else class="right">
                    <li>
                        <a class="dropdown-trigger" data-target="langs">
                            <i class="material-icons left">language</i>
                            {{ language.abbreviation }}
                        </a>
                    </li>
                    <li>
                        <router-link to="/login">{{ t('navigation.login') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/register">{{ t('navigation.signup') }}</router-link>
                    </li>
                </ul>
                <ul id="langs" class="dropdown-content">
                    <li
                        v-for="lang in state.languages"
                        :key="lang.id"
                        :class="{ active: lang.abbreviation === user?.locale }"
                        @click="changeLanguage(lang)">
                        <a>{{ lang.localName }}</a>
                    </li>
                </ul>
            </div>
        </nav>

        <nav v-if="!user" id="nav-mobile-unauthenticated" class="nav hide-on-med-and-up">
            <div class="nav-wrapper">
                <a class="nav-logo">
                    <router-link to="/index">
                        <img
                            alt="Hometheca Logo"
                            src="@/assets/logo_nav.png"
                            class="responsive-img">
                    </router-link>
                </a>
                <ul class="right">
                    <li>
                        <a class="dropdown-trigger" data-target="langs-mobile">
                            <i class="material-icons left">language</i>
                            {{ language.abbreviation }}
                        </a>
                    </li>
                </ul>
                <ul id="langs-mobile" class="dropdown-content">
                    <li
                        v-for="lang in state.languages"
                        :key="lang.id"
                        :class="{ active: lang.abbreviation === user?.locale }"
                        @click="changeLanguage(lang)">
                        <a>{{ lang.localName }}</a>
                    </li>
                </ul>
            </div>
        </nav>

        <main>
            <router-view />
        </main>
        <MobileFooter v-if="user" />
    </div>
</template>

<script>
import { reactive, computed, onBeforeMount, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
import router from '@/router';
import M from 'materialize-css';
import Requester from '@/classes/Requester';
import BookRequester from '@/classes/BookRequester';
import MobileFooter from '@/components/MobileFooter';
import { setI18nLanguage, loadLocaleMessages } from './lang'

export default {
    components: {
        MobileFooter
    },
    setup() {
        const i18n = useI18n();
        const { t } = i18n;
        const getLocalLang = () =>
            (localStorage.getItem('locale') || navigator.language || navigator.browserLanguage || ( navigator.languages || [ "en" ] )[ 0 ]).substring(0, 2);
        let state = reactive({
            languages: []
        })
        onBeforeMount(async() => {
            const store = useStore();
            state.languages = await Requester.getJson('/api/language');
            store.dispatch('setLanguages', state.languages);
            let user = store.state.User.user;
            if(!user) {
                let lang = getLocalLang();
                await loadLocaleMessages(i18n, lang);
                setI18nLanguage(i18n, lang);
                store.dispatch('setCurrentLanguage', store.state.languages.find(l => l.abbreviation === lang))
                return;
            }
            if(user.locale) {
                await loadLocaleMessages(i18n, user.locale);
                setI18nLanguage(i18n, user.locale);
            }
            let books = await BookRequester.fetchBooks();
            store.dispatch('Book/setBooks', books);
        })
        onMounted(() => {
            let elems = document.querySelectorAll('.dropdown-trigger');
            M.Dropdown.init(elems, {});
            
            // let defferedPrompt;
            // let btnAdd = document.querySelector('#btnAdd');
            // window.addEventListener('beforeinstallprompt', e => {
            //     e.preventDefault();
            //     defferedPrompt = e;
            //     btnAdd.style.display = "block";
            // })
            // btnAdd.addEventListener('click', e => {
            //     defferedPrompt.prompt();
            //     defferedPrompt.userChoice.then(res => {
            //         if(res.outcome === "accepted") console.log('acc');
            //         defferedPrompt = null;
            //     })
            // })
        })
        const store = useStore();
        const user = computed(() => store.state.User.user);
        const language = computed(() => store.state.currentLanguage);
        let changeLanguage = async(lang) => {
            await loadLocaleMessages(i18n, lang.abbreviation);
            setI18nLanguage(i18n, lang.abbreviation);
            store.dispatch('setCurrentLanguage', lang);
            localStorage.setItem('locale', lang.abbreviation);
        }
        return {
            user,
            language,
            state,
            changeLanguage,
            t
        }
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    overflow-x: hidden;
}
#btnAdd {
    display: none;
}
.nav {
    background-color: #fff;
    box-shadow: none;
    .nav-logo {
        img {
            padding: 6px;
            transition: all 0.3s;
            &:hover {
                filter: brightness(0.6);
                transition: all 0.3s;
            }
        }
    }
    a {
        color: #2c3e50;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
#nav-mobile-unauthenticated {
    height: 48px;
    line-height: 48px;
    .nav-logo {
        img {
            height: 42px;
        }
    }
    i {
        height: 48px;
        line-height: 48px;
    }
}
</style>