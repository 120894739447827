import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Index from '../views/Index.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Home from '../views/Home.vue';
import Library from '../views/Library.vue';
import Scanner from '../views/Scanner.vue';
import User from '../classes/User';

const checkUser = (to, from, next) => {
    if(store.state.User.user) return next();
    let user = localStorage.getItem('user');
    if(!user) return next('/login');
    user = new User(JSON.parse(user));
    store.dispatch('User/setUser', user);
    next();
}

const checkUserStart = (to, from, next) => {
    if(store.state.User.user) return next('/home');
    let user = localStorage.getItem('user');
    if(!user) return next();
    user = new User(JSON.parse(user));
    store.dispatch('User/setUser', user);
    next('/home');
}


let setSlideClass = (to, from) => {
	if(from.name === 'Home') to.params.slideClass = 'slider-from-left slide-from-left'
	else if(from.name === 'Library') to.params.slideClass = 'slider-from-right slide-from-right'
}

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
		beforeEnter: checkUserStart
    },
    {
        path: '/index',
        name: 'IndexExplicit',
        component: Index
    },
    {
        path: '/login/:token',
        name: 'Reset',
        component: Login
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: checkUserStart
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        beforeEnter: checkUserStart
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
		beforeEnter: checkUser
    },
    {
        path: '/library',
        name: 'Library',
        component: Library,
        beforeEnter: checkUser
    },
    {
        path: '/add',
        name: 'AddBook',
        component: Scanner,
        beforeEnter: (to, from, next) => {
			setSlideClass(to, from);
			checkUser(to, from, next);
		} 
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
